<template>
  <b-card>
    <b-modal
      id="permission-settings-modal"
      ref="modal"
      no-close-on-backdrop
      :title="
        editData ? 'Edit Share Permission Setting' : 'Share Permission Setting'
      "
      :ok-title="editData ? 'Update' : 'Save'"
      cancel-variant="outline-secondary"
      @ok="formSubmit"
    >
      <form
        ref="form"
        @submit.stop.prevent="addPermissionSetting"
      >
        <!-- Upload File -->
        <b-row>
          <b-col
            cols="11"
            md="11"
            class="mb-md-0 mb-2"
          >
            <b-row>
              <b-col>
                <b-form-group
                  invalid-feedback="Employee is required"
                  :state="employeeState"
                >
                  <vue-autosuggest
                    v-if="!editData"
                    v-model="employee_name"
                    :suggestions="searchEmployees"
                    :limit="10"
                    :input-props="{id:'autosuggest__input',class:'form-control', placeholder:'Search employee name'}"
                    @selected="onSelected"
                    @input="onInputChange"
                  >
                    <template slot-scope="{suggestion}">
                      <span class="my-suggestion-item">{{ suggestion.item.first_name }} {{ suggestion.item.last_name }}</span>
                    </template>
                  </vue-autosuggest>
                  <b-form-input
                    v-else
                    v-model="employee_name"
                    :state="employeeState"
                    :disabled="editData"
                    required
                  />

                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  v-slot="{ ariaDescribedby }"
                  invalid-feedback="Action is required"
                  :state="actionState"
                >
                  <b-form-checkbox-group
                    v-model="action_access"
                    :options="options"
                    :aria-describedby="ariaDescribedby"
                    text-field="text"
                    name="flavour-2a"
                    stacked
                    :state="actionState"
                    required
                  />
                </b-form-group>
                <!-- {{ action_access }} -->
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </form>
    </b-modal>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-between">
      <b-form-group>
        <b-button
          v-b-modal.permission-settings-modal
          variant="outline-primary"
          @click="resetForm"
        >
          Add Share Permissions
        </b-button>
      </b-form-group>
      <b-form-group>
        <v-select
          v-model="pcConfigData.id"
          class="style-chooser"
          placeholder="Select Status"
          label="name"
          :options="searchStatus"
          :reduce="val => val.id"
          @input="searchStatusFunc"
        />
      </b-form-group>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="permissionSettings"
      :rtl="direction"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      theme="my-theme"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <div v-if="filterCategory.value" />
        <!-- Column: Insert -->
        <span v-if="props.column.field === 'insert'">
          <span class="d-flex">
            <p v-if="props.row.action_access.includes('insert')">Yes</p>
            <p v-else>No</p>
          </span>
        </span>
        <!-- Column: Update -->
        <span v-else-if="props.column.field === 'update'">
          <span class="d-flex">
            <p v-if="props.row.action_access.includes('update')">Yes</p>
            <p v-else>No</p>
          </span>
        </span>
        <!-- Column: Delete -->
        <span v-else-if="props.column.field === 'delete'">
          <span class="d-flex">
            <p v-if="props.row.action_access.includes('delete')">Yes</p>
            <p v-else>No</p>
          </span>
        </span>
        <!-- Column: Share -->
        <span v-else-if="props.column.field === 'share'">
          <span class="d-flex">
            <p v-if="props.row.action_access.includes('share')">Yes</p>
            <p v-else>No</p>
          </span>
        </span>
        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <span class="d-flex">
            <p v-if="props.row.status == 1">Active</p>
            <p v-else>Inactive</p>
          </span>
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span class="d-flex">
            <b-button
              class="mr-1 px-1"
              variant="outline-primary"
              @click="permissionActiveDeactive(props.row)"
            >
              {{ props.row.status == 1 ? "Deactivate" : "Activate" }}
            </b-button>
            <b-button
              class="mr-1 px-1"
              variant="outline-warning"
              @click="editRow(props.row)"
            >Edit</b-button>
            <b-button
              class="px-1"
              variant="outline-danger"
              @click="deletepermissionSetting(props.row)"
            >Delete</b-button>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','30','40','50']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card>
</template>
<script>
import {
  BCard,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormCheckboxGroup,
  BRow,
  BCol,
  VBModal,
  BButton,
  // BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import store from '@/store/index'
// import Multiselect from 'vue-multiselect'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueAutosuggest } from 'vue-autosuggest'

export default {
  components: {
    BCard,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckboxGroup,
    BRow,
    BCol,
    BButton,
    // Multiselect,
    VueAutosuggest,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      id: '',
      employee_id: '',
      employee_name: '',
      file_name: '',
      employees: [],
      selected: null,
      searchEmployees: [],
      action_access: [],
      limit: 10,
      employeeState: null,
      actionState: null,
      editData: false,
      editFormNumber: null,
      pcConfigData: {},
      columns: [
        {
          label: 'Employee Name',
          field: 'employee_name',
        },
        {
          label: 'Insert',
          field: 'insert',
        },
        {
          label: 'Update',
          field: 'update',
        },
        {
          label: 'Delete',
          field: 'delete',
        },
        {
          label: 'Share',
          field: 'share',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      filterCategory: [],
      filterCategories: [
        { value: 1, text: 'Active' },
        { value: 0, text: 'Inactive' },
      ],
      options: [
        { value: 'insert', text: 'Insert' },
        { value: 'update', text: 'Update' },
        { value: 'delete', text: 'Delete' },
        { value: 'share', text: 'Share' },
      ],
      tempSettings: [],
      permissionSettings: [],
      searchStatus: [
        { id: 1, name: 'Active' },
        { id: 0, name: 'Inactive' },
      ],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted() {
    this.getResourcePermissionSettings()
    this.getResourceEmployees()
  },
  methods: {
    searchStatusFunc(val) {
      this.permissionSettings = this.tempSettings
      if (val === 0 || val === 1) {
        this.permissionSettings = this.permissionSettings.filter(
          e => e.status === val,
        )
      } else {
        //
      }
    },
    getResourcePermissionSettings() {
      this.$http.get(`${window.apiUrl}resource-sharing/get-resource-permission-settings`)
        .then(res => {
          this.permissionSettings = res.data.data
          this.tempSettings = res.data.data
        })
    },
    getResourceEmployees() {
      this.$http.get(`${window.apiUrl}resource-sharing/get-resource-employees`)
        .then(res => {
          this.employees = res.data.data
        })
    },
    onSelected(option) {
      this.employee_id = option.item.employee_id
      this.employee_name = `${option.item.first_name} ${option.item.last_name}`
    },
    onInputChange(search) {
      if (search === '' || search === undefined) {
        return
      }
      const filteredData = this.employees.filter(item => item.first_name.toLowerCase().indexOf(search.toLowerCase()) > -1).slice(0, this.limit)
      this.searchEmployees = [{
        data: filteredData,
      }]
    },
    checkFormValidity() {
      this.employeeState = null
      this.actionState = null
      if (this.employee_id === '' || this.employee_name === '') {
        this.employeeState = false
        return false
      }
      if (this.action_access.length < 1) {
        this.actionState = false
        return false
      }
      return true
    },
    filterData(value) {
      this.permissionSettings = this.tempSettings
      this.permissionSettings = this.permissionSettings.filter(
        e => e.status === value,
      )
    },
    addPermissionSetting() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      this.$http.post(`${window.apiUrl}resource-sharing/add-permission-setting`, {
        employee_id: this.employee_id,
        action_access: this.action_access,
      })
        .then(res => {
          if (res.data.status) {
            this.getResourcePermissionSettings()
            this.resetForm()
            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide('permission-settings-modal')
              this.$swal({
                icon: 'success',
                title: 'Added!',
                text: 'Permission setting added successfully',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
    updatePermissionSetting() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      this.$http.put(`${window.apiUrl}resource-sharing/update-permission-setting/${this.id}`, {
        employee_id: this.employee_id,
        action_access: this.action_access,
      })
        .then(res => {
          if (res.data.status) {
            this.getResourcePermissionSettings()
            this.resetForm()
            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide('permission-settings-modal')
              this.$swal({
                icon: 'success',
                title: 'Updated!',
                text: 'Permission setting updated successfully',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
    permissionActiveDeactive(rowData) {
      this.$swal({
        title: 'Are you sure to change the record',
        // text: "You won't be able to revert this!",
        icon: 'danger',
        showCancelButton: true,
        confirmButtonText: 'Yes, Change it.',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.get(`${window.apiUrl}resource-sharing/active-deactive-permission-setting/${rowData.id}`)
            .then(res => {
              if (res.data.status) {
                this.getResourcePermissionSettings()
                this.$swal({
                  icon: 'success',
                  title: 'Updated!',
                  text: 'Record updated successfully',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: res.data.message,
                    icon: 'XIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(err => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: err.message,
                  icon: 'XIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    deletepermissionSetting(rowData) {
      this.$swal({
        title: 'Are you sure to delete?',
        text: "You won't be able to revert this!",
        icon: 'danger',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`${window.apiUrl}resource-sharing/delete-permission-setting/${rowData.id}`)
            .then(res => {
              if (res.data.status) {
                this.getResourcePermissionSettings()
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Permission setting deleted successfully',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: res.data.message,
                    icon: 'XIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(err => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: err.message,
                  icon: 'XIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    formSubmit(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      if (this.editData) {
        this.updatePermissionSetting()
      } else {
        this.addPermissionSetting()
      }
    },
    editRow(rowData) {
      this.resetForm()
      this.id = rowData.id
      this.employee_id = rowData.employee_id
      this.employee_name = rowData.employee_name
      this.action_access = rowData.action_access
      this.editData = true
      this.$nextTick(() => {
        this.$bvModal.show('permission-settings-modal')
      })
    },
    resetForm() {
      this.action_access = []
      this.editData = false
      this.id = ''
      this.employee_name = ''
      this.employee_id = ''
      this.employeeState = null
      this.actionState = null
      this.filterCategory = []
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";

.v-select.style-chooser.vs--single.vs--searchable {
  width: 150px;
}
</style>
